<script>
import ax from '../helpers/restful/service';

export default {
    data() {
        return {
            showModal: false,
            audit_data: [],
        }
    },
    mounted() {
        this.getAuditData();
    },
    methods: {
        closeModal() {
            this.showModal = !this.showModal;
        },
        async getAuditData(){
            console.log("get audit data");
            const resp = await ax.post("/get-audit-data", { });
            console.log("audit data:", resp);
            if(resp.status == 200){
                this.audit_data = resp.data.audit_data;
                return;
            }
            this.audit_data = [];
            return;
        },
        
    },
};
</script>

<template>
    <div>
        <div class="bg-black py-2 px-2 mb-2 ">
            <h5 class="text-white">รายชื่อผู้ตรวจ</h5>
        </div>
        <div class="d-block d-grid">
            <b-button variant="primary" block lg="5" @click="showModal = !showModal;" type="button">
                <span style="font-size: 20px;">
                    ดูเพิ่มเติม
                </span>
            </b-button>
        </div>

        <b-modal size="xl" v-model="showModal" title="รายชื่อผู้ตรวจ" no-close-on-esc no-close-on-backdrop
            hide-header-close scrollable centered>
            <h4 class="fs-5 text text-primary">รายชื่อผู้ตรวจมาตรฐานการแพทย์แผนไทย ที่ได้รับการอนุมัติจากสภาการแพทย์แผนไทย</h4>
            <table class="table table-bordered text-center">
                <thead>
                    <tr>
                        <th>รหัส</th>
                        <th>ชื่อ-สกุล</th>
                        <th>วันที่อนุมัติ</th>
                        <th>จำนวนผู้สมัครโดยใช้รหัสส่วนลด</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in audit_data" :key="index">
                        <td>{{ "VS"+ item.course_inspection_uid.toString().padStart(6, "0") }}</td>
                        <td>{{ item.course_inspection_name }}</td>
                        <td>{{ new Date(item.course_inspection_validate_certified_dt).toLocaleDateString('th-TH', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}</td>
                        <td>{{ item.discount_used }}</td>
                    </tr>
                </tbody>
            </table>

            <template #modal-footer>
                <div class="col-5 d-grid">
                    <b-button variant="danger" size="xl" @click="closeModal">
                        <h5 class="text-white">ปิด</h5>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import ax from '../helpers/restful/service';
import Receipt from '../components/receipt-2.vue';
import { required, numeric, maxLength, minLength } from "vuelidate/lib/validators";

export default {
    props:{
        additional_text: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            showModal: false,
            showConfirmModal: false,
            showConfirmInstallmentModal: false,
            showReceiptModal: false,
            showLinkModal: false,
            link: "https://thaimed.v2vservice.com/course-registed?open_dialog=l3",
            payment: {
                token: process.env.VUE_APP_GB_TOKEN,
            },
            isExist: false,
            isPaid: false,
            registed_data: null,
            installment_no: 0,

            identity_number: "",
            firstname: "",
            lastname: "",
            phone: "",
            submitted: false,
            amount_origin: 18000,
            amount: 18000,

            discount_code: null,
            check_discount_timeout: null,
            check_loading: false,
        }
    },
    validations: {
        identity_number: {
            required,
            numeric,
            maxLength: maxLength(13),
            minLength: minLength(13),
        },
        firstname: {
            required,
        },
        lastname: {
            required,
        },
        phone: {
            required,
            numeric,
            maxLength: maxLength(10),
            minLength: minLength(10),
        },
    },
    components: {
        Receipt,
    },
    created() {
        this.checkL3Status();
    },
    mounted(){
        console.log("this.$route.query.open_dialog", this.$route.query.open_dialog);
        if(this.$route.query.open_dialog == "l3"){
            this.showModal = true;
        }
    },
    watch: {
        discount_code: {
            handler: async function (n, o) {
                if(!this.check_loading) {
                    this.check_loading = true;
                }
                if (this.check_discount_timeout != null) {
                    clearTimeout(this.check_discount_timeout);
                }
                this.check_discount_timeout = setTimeout(async () => {
                    console.log("n:", n);
                    console.log("o:", o);
                    const resp = await ax.post("/get-discount", {
                        discount_code : n,
                        l: "l3",
                    });
                    console.log("resp:", resp);
                    this.amount = this.amount_origin;
                    if(resp.data.is_exist){
                        const discount_percent = resp.data.discount_percent;
                        this.amount = this.roundN(((100 - discount_percent) * 0.01 * this.amount_origin), 2);
                    } 
                    this.check_loading = false;
                }, 2000);
            },
            deep: true,
        }
    },
    methods: {
        closeModal() {
            this.showModal = !this.showModal;
        },
        openConfirmModal(price) {
            this.amount = price;
            this.showConfirmModal = true;
        },
        openConfirmInstallmentModal() {
            this.showConfirmInstallmentModal = true;
        },
        openReceiptModal() {
            this.showReceiptModal = true;
        },
        openLinkModal(){
            this.showLinkModal = true;
        },
        roundN(num,n){
            return parseFloat(Math.round(num * Math.pow(10, n)) /Math.pow(10,n)).toFixed(n);
        },
        inspectForm() {
            this.submitted = true;

            this.$v.identity_number.$touch();
            this.$v.firstname.$touch();
            this.$v.lastname.$touch();
            this.$v.phone.$touch();

            if (this.$v.identity_number.$invalid || this.$v.firstname.$invalid || this.$v.lastname.$invalid || this.$v.phone.$invalid) {
                console.log("this.$v.$invalid:", this.$v.$invalid);
                console.log('form is invalid');
                return;
            }

            this.tryToRegistedL3(false);
        },
        inspectInstallmentForm() {
            this.submitted = true;

            this.$v.identity_number.$touch();
            this.$v.firstname.$touch();
            this.$v.lastname.$touch();
            this.$v.phone.$touch();

            if (this.$v.identity_number.$invalid || this.$v.firstname.$invalid || this.$v.lastname.$invalid || this.$v.phone.$invalid) {
                console.log("this.$v.$invalid:", this.$v.$invalid);
                console.log('form is invalid');
                return;
            }

            this.tryToRegistedL3(true);
        },
        async tryToRegistedL3(installment) {
            this.showConfirmModal = false;
            const uid = JSON.parse(localStorage.getItem('auth.currentUser')).uid;
            console.log("uid:", uid);
            const resp = await ax.post("/registed-l3", {
                uid: uid,
                identity_number: this.identity_number,
                firstname: this.firstname,
                lastname: this.lastname,
                phone: this.phone,
                installment: installment,
                amount_origin: this.amount_origin,
                discount_code: this.discount_code,
            });
            console.log("resp:", resp);
            if (resp.status != 200) {
                alert("เกิดข้อผิดพลาด กรุณาดำเนินการใหม่อีกครั้ง");
                return;
            }

            const responseData = resp.data;

            if (responseData.is_created || (!responseData.is_created && !responseData.is_complete && responseData.is_exist)) {
                const form = document.createElement("form");
                form.method = "POST";
                form.action = "https://api.gbprimepay.com/gbp/gateway/qrcode";
                form.enctype = "application/x-www-form-urlencoded";
                form.target = "_blank";

                const token = document.createElement("input");
                token.value = this.payment.token;
                token.type = "hidden";
                token.name = "token";
                form.appendChild(token);

                let amount = document.createElement("input");
                amount.value = responseData.amount + responseData.fee;
                amount.type = "hidden";
                amount.name = "amount";
                form.appendChild(amount);

                let referenceNo = document.createElement("input");
                referenceNo.value = responseData.referenceNo;
                referenceNo.type = "hidden";
                referenceNo.name = "referenceNo";
                form.appendChild(referenceNo);

                let backgroundUrl = document.createElement("input");
                backgroundUrl.value = responseData.backgroundUrl;
                backgroundUrl.type = "hidden";
                backgroundUrl.name = "backgroundUrl";
                form.appendChild(backgroundUrl);

                document.body.appendChild(form);
                form.submit();
                form.remove();
            }

            this.$router.go();
        },
        async checkL3Status() {
            const uid = JSON.parse(localStorage.getItem('auth.currentUser')).uid;
            const resp = await ax.post("/check-status-l3", { uid: uid });
            console.log("resp:", resp);
            if (resp.status != 200) {
                alert("เกิดข้อผิดพลาด กรุณาดำเนินการใหม่อีกครั้ง");
                return;
            }
            this.isExist = resp.data.is_exist;
            this.isPaid = resp.data.is_paid;
            this.registed_data = resp.data;
            this.installment_no = resp.data.installment_no;
        },
        async tryToPayAgain() {
            const form = document.createElement("form");
            form.method = "POST";
            form.action = "https://api.gbprimepay.com/gbp/gateway/qrcode";
            form.enctype = "application/x-www-form-urlencoded";
            form.target = "_blank";

            const token = document.createElement("input");
            token.value = this.payment.token;
            token.type = "hidden";
            token.name = "token";
            form.appendChild(token);

            let amount = document.createElement("input");
            amount.value = this.registed_data.amount + this.registed_data.fee;
            amount.type = "hidden";
            amount.name = "amount";
            form.appendChild(amount);

            let referenceNo = document.createElement("input");
            referenceNo.value = this.registed_data.referenceNo;
            referenceNo.type = "hidden";
            referenceNo.name = "referenceNo";
            form.appendChild(referenceNo);

            let backgroundUrl = document.createElement("input");
            backgroundUrl.value = this.registed_data.backgroundUrl;
            backgroundUrl.type = "hidden";
            backgroundUrl.name = "backgroundUrl";
            form.appendChild(backgroundUrl);

            document.body.appendChild(form);
            form.submit();
            form.remove();
        },
    },
};
</script>

<template>
    <div>
        <div class="bg-black py-2 px-2 mb-2 ">
            <h5 class="text-white">หลักสูตรฝึกอบรมผู้ตรวจมาตรฐานการแพทย์แผนไทย (ภาคทฤษฎี)</h5>
        </div>
        <h5>
            <span class="fs-6 text-danger" v-if="additional_text != null && additional_text != undefined">{{ additional_text }}</span> <br v-if="additional_text != null && additional_text != undefined"/>
            เปิดลงทะเบียน  :<br />
            ๒๔ สิงหาคม ๒๕๖๖ ถึง ๒๗ ตุลาคม ๒๕๖๖
        </h5>
        <div class="d-block d-grid">
            <b-button variant="primary" block lg="5" @click="showModal = !showModal;" type="button">
                <span style="font-size: 20px;">
                    ดูเพิ่มเติม
                </span>
            </b-button>
        </div>

        <b-modal size="xl" v-model="showModal" title="สมัครเข้าอบรม" no-close-on-esc no-close-on-backdrop hide-header-close
            scrollable centered>
            <template #modal-header>
                <div class="col-12 text-end ">
                    <div class="me-0 end-0 top-0">
                        <button class="btn btn-outline-secondary" type="button"  @click="openLinkModal"><i class="fa fa-share-alt"></i></button>
                    </div>
                </div>
            </template>
            <h4 class="text-warning">หลักสูตรฝึกอบรมผู้ตรวจมาตรฐานการแพทย์แผนไทย (ภาคทฤษฎี)</h4>
            <p class="fs-5 text">
                <span class="fs-4 text">คุณสมบัติ ผู้สมัคร</span><br />
                ๑. มีสัญชาติไทย<br />
                ๒. มีอายุไม่ต่ำกว่ายี่สิบปี<br />
                ๓. ไม่เป็นผู้ประพฤติเสียหายซึ่งคณะกรรมการเห็นว่าจะนำมาซึ่งความเสื่อมเสียเกียรติศักดิ์แห่งวิชาชีพ<br />
                ๔.
                ไม่เป็นผู้เคยต้องโทษจำคุกโดยคำพิพากษาถึงที่สุดในคดีที่จะนำมาซึ่งความเสื่อมเสียเกียรติศักดิ์แห่งวิชาชีพ<br />
                ๕. ไม่เป็นผู้วิกลจริต จิตฟั่นเฟือนไม่สมประกอบ หรือไม่เป็นโรคที่กำหนดไว้ในข้อบังคับสภาการแพทย์แผนไทย<br />
            </p>
            <p class="fs-5 text">
                <span class="fs-4 text">กำหนดการ รับสมัคร</span><br />
                วันที่ ๒๔ สิงหาคม ๒๕๖๖ ถึง ๒๗ ตุลาคม ๒๕๖๖
            </p>
            <p class="fs-5 text">
                <span class="fs-4 text">สิทธิที่จะได้รับ</span><br />
                - คุณสมบัติเพื่อขึ้นทะเบียนเป็นผู้ตรวจสอบมาตรฐานทางการแพทย์แผนไทย<br />
                - คุณสมบัติเพื่อขึ้นทะเบียนและให้การรับรองมาตรฐานอื่นๆ ตามที่ สภาการแพทย์แผนไทย กำหนด หรือ
                รับรองแล้วแต่กรณี<br />
                - สิทธิในการพิจารณารับรองสถานะ “ตรวจสอบแล้ว (Verified)” ในการขึ้นทะเบียนและให้การรับรองมาตรฐานการแพทย์แผนไทย
                เมื่อได้รับการขึ้นทะเบียนฯ<br />
                - สิทธิอื่นๆ ตามที่ สภาการแพทย์แผนไทย กำหนด หรือ รับรองแล้วแต่กรณี<br />
            </p>
            <p class="fs-5 text">
                <span class="fs-4 text">กำหนดการ อบรม</span><br />
                เปิด : ๒ พฤศจิกายน ๒๕๖๖<br />
                จบ : ๒๙ ธันวาคม ๒๕๖๖<br />
                รายละเอียด : <a href="/pdf/l3.pdf" target="_blank">รายละเอียด</a>
            </p>
            <p>
                <span class="fs-4 text">ค่าสมัคร</span><br />
                <span class="fs-2 text text-info">18,000 บาท</span><br />
                <span class="fs-5 text text-warning">ยังไม่รวมค่าดำเนินการ และค่าบริการทางอินเตอร์เนต 30 บาท / ครั้ง</span>
            </p>
            
            <template #modal-footer>
                <div class="container">
                    <!-- <div v-if="!isExist && !isPaid" class="row mb-1">
                        <div class="col-1 col-xs-2 col-sm-2 col-md-2 col-xl-1">
                            <img src="@/assets/images/arrow-right.png" class="img-fluid" />
                        </div>
                        <div class="col-11 col-xs-10 col-sm-10 col-md-10 col-xl-11 d-grid g-0">
                            <b-button variant="primary" md size="md" @click="openConfirmInstallmentModal">
                                <h5 class="text-white text-start">ผ่อนชำระ 0% X 10 งวด จากราคาเต็ม 18,000 บาท </h5>
                            </b-button>
                        </div>
                    </div> -->
    
                    <div v-if="!isExist && !isPaid" class="row mb-1">
                        <div class="col-1 col-xs-2 col-sm-2 col-md-2 col-xl-1">
                            <img src="@/assets/images/arrow-right.png" class="img-fluid" />
                        </div>
                        <div class="col-11 col-xs-10 col-sm-10 col-md-10 col-xl-11 d-grid g-0">
                            <b-button variant="primary" md size="md" @click="openConfirmModal(18000)">
                                <h5 class="text-white text-start">ชำระ 18,000 บาท</h5>
                            </b-button>
                        </div>
                    </div>
    
                    <!-- <div v-if="!isExist && !isPaid" class="row mb-1">
                        <div class="col-1 col-xs-2 col-sm-2 col-md-2 col-xl-1">
                            <img src="@/assets/images/arrow-right.png" class="img-fluid" />
                        </div>
                        <div class="col-11 col-xs-10 col-sm-10 col-md-10 col-xl-11 d-grid g-0">
                            <b-button variant="primary" md size="md" @click="openConfirmModal(5500)">
                                <h5 class="text-white text-start">เฉพาะ!! ผู้ประกอบวิชาชีพการแพทย์แผนไทย/ไทยประยุกต์ ลดเหลือเพียง 5,500 บาท
                                    เมื่อชำระทันที
                                </h5>
                            </b-button>
                        </div>
                    </div> -->
                </div>

                <div v-if="isExist && !isPaid && registed_data != null && registed_data.resultCode == null"
                    class="col-5 d-grid">
                    <h6 class="text text-danger text-start">
                        รอชำระเงิน งวดที่ {{ installment_no }} จำนวน {{ registed_data.amount }} บาท
                    </h6>
                    <small class="text text-start text-danger">ค่าดำเนินการ และค่าบริการทางอินเตอร์เนต {{ registed_data.fee }} บาท / ครั้ง</small>
                </div>

                <div v-if="isExist && !isPaid && registed_data != null && registed_data.resultCode == null"
                    class="col-5 d-grid">
                    <b-button variant="warning" size="xl" @click="tryToPayAgain">
                        <h5 class="text-white">ชำระเงิน</h5>
                    </b-button>
                </div>

                <!-- if paid -->
                <div v-if="isExist && isPaid" class="col-5 d-grid">
                    <b-button variant="success" size="xl" @click="openReceiptModal">
                        <h5 class="text-white">ดาวน์โหลดใบเสร็จรับเงิน</h5>
                    </b-button>
                </div>
                <!-- end if paid -->
                <div class="col-5 d-grid">
                    <b-button variant="danger" size="xl" @click="closeModal">
                        <h5 class="text-white">ปิด</h5>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showConfirmModal" title="ยืนยันการชำระเงิน" no-close-on-esc no-close-on-backdrop
            hide-header-close centered>
            <b-form @submit.prevent="inspectForm">
                <h4 class="text-warning">กรอกข้อมูลเพื่อดำเนินการต่อ</h4>
                <b-form-group class="mb-3" label="กรุณากรอก ชื่อ">
                    <b-form-input v-model="firstname" type="text" placeholder="ชื่อจริง"
                        :class="{ 'is-invalid': submitted && $v.firstname.$error }"></b-form-input>
                    <div v-if="submitted && $v.firstname.$error" class="invalid-feedback">
                        <div v-if="!$v.firstname.required">ต้องกรอกชื่อ</div>
                    </div>
                </b-form-group>

                <b-form-group class="mb-3" label="กรุณากรอก นามสกุล">
                    <b-form-input v-model="lastname" type="text" placeholder="นามสกุลจริง"
                        :class="{ 'is-invalid': submitted && $v.lastname.$error }"></b-form-input>
                    <div v-if="submitted && $v.lastname.$error" class="invalid-feedback">
                        <div v-if="!$v.lastname.required">ต้องกรอกนามสกุล</div>
                    </div>
                </b-form-group>

                <b-form-group class="mb-3" label="กรุณากรอก เลขประจำตัวประชาชน 13 หลัก">
                    <b-form-input v-model="identity_number" type="text"
                        placeholder="เลขประจำตัวประชาขน 13 หลัก เช่น 1002345678991 เป็นต้น"
                        :class="{ 'is-invalid': submitted && $v.identity_number.$error }" maxlength="13"></b-form-input>
                    <div v-if="submitted && $v.identity_number.$error" class="invalid-feedback">
                        <div v-if="!$v.identity_number.required">ต้องกรอกหมายเลขบัตรประชาชน</div>
                        <div v-if="!$v.identity_number.numeric">
                            ต้องกรอกหมายเลขบัตรประชาชนเป็นตัวเลขเท่านั้น</div>
                        <div v-if="!$v.identity_number.maxLength">ต้องกรอกหมายเลขบัตรประชาชน ไม่เกินกว่า
                            13 หลัก</div>
                        <div v-if="!$v.identity_number.minLength">ต้องกรอกหมายเลขบัตรประชาชน ไม่น้อยกว่า
                            13 หลัก</div>
                    </div>
                </b-form-group>

                <b-form-group class="mb-3" label="กรุณากรอก เบอร์โทรศัพท์ 10 หลัก">
                    <b-form-input v-model="phone" type="text" placeholder="เบอร์โทรศัพท์ติดต่อกลับ"
                        :class="{ 'is-invalid': submitted && $v.phone.$error }" maxlength="10"></b-form-input>
                    <div v-if="submitted && $v.identity_number.$error" class="invalid-feedback">
                        <div v-if="!$v.phone.required">ต้องกรอกหมายเลขบัตรประชาชน</div>
                        <div v-if="!$v.phone.numeric">
                            ต้องกรอกตัวเลขเท่านั้น</div>
                        <div v-if="!$v.phone.maxLength">ต้องกรอกหมายเลข ไม่เกินกว่า
                            10 หลัก</div>
                        <div v-if="!$v.phone.minLength">ต้องกรอกหมายเลข ไม่น้อยกว่า
                            10 หลัก</div>
                    </div>
                </b-form-group>

                <b-form-group class="mb-3" label="กรุณากรอก รหัสสิทธิพิเศษประจำกลุ่ม">
                    <b-form-input v-model="discount_code" type="text" placeholder="รหัสสิทธิพิเศษประจำกลุ่ม" maxlength="20"
                        minlength="20"></b-form-input>
                </b-form-group>
            </b-form>
            
            <h4 class="text-warning">ราคาที่ต้องชำระ {{ (check_loading ? "***** กำลังประมวลผล *****" : amount) }} บาท</h4>

            <h4 class="text-warning">ยืนยันการทำรายการชำระหลักสูตรฝึกอบรมผู้ตรวจมาตรฐานการแพทย์แผนไทย (ภาคทฤษฎี)</h4>

            <p class="mt-2 fs-4 text text-danger">ขอสงวนสิทธิไม่คืนเงินทุกกรณี โปรดพิจารณาและตรวจสอบก่อนยืนยันชำระเงิน</p>
            
            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="danger" size="xl" type="button" @click="inspectForm" :disabled="check_loading">
                        <h5 class="text-white">ยืนยัน</h5>
                    </b-button>
                </div>

                <div class="col-5 d-block d-grid">
                    <b-button variant="" size="xl" @click="showConfirmModal = !showConfirmModal;">
                        <h5 class="text-white">ปิด</h5>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showConfirmInstallmentModal" title="ยืนยันการชำระเงินแบบผ่อน 10 งวด" no-close-on-esc
            no-close-on-backdrop hide-header-close centered>
            <b-form @submit.prevent="inspectInstallmentForm">
                <h4 class="text-warning">กรอกข้อมูลเพื่อดำเนินการต่อ</h4>
                <b-form-group class="mb-3" label="กรุณากรอก ชื่อ">
                    <b-form-input v-model="firstname" type="text" placeholder="ชื่อจริง"
                        :class="{ 'is-invalid': submitted && $v.firstname.$error }"></b-form-input>
                    <div v-if="submitted && $v.firstname.$error" class="invalid-feedback">
                        <div v-if="!$v.firstname.required">ต้องกรอกชื่อ</div>
                    </div>
                </b-form-group>

                <b-form-group class="mb-3" label="กรุณากรอก นามสกุล">
                    <b-form-input v-model="lastname" type="text" placeholder="นามสกุลจริง"
                        :class="{ 'is-invalid': submitted && $v.lastname.$error }"></b-form-input>
                    <div v-if="submitted && $v.lastname.$error" class="invalid-feedback">
                        <div v-if="!$v.lastname.required">ต้องกรอกนามสกุล</div>
                    </div>
                </b-form-group>

                <b-form-group class="mb-3" label="กรุณากรอก เลขประจำตัวประชาชน 13 หลัก">
                    <b-form-input v-model="identity_number" type="text"
                        placeholder="เลขประจำตัวประชาขน 13 หลัก เช่น 1002345678991 เป็นต้น"
                        :class="{ 'is-invalid': submitted && $v.identity_number.$error }" maxlength="13"></b-form-input>
                    <div v-if="submitted && $v.identity_number.$error" class="invalid-feedback">
                        <div v-if="!$v.identity_number.required">ต้องกรอกหมายเลขบัตรประชาชน</div>
                        <div v-if="!$v.identity_number.numeric">
                            ต้องกรอกหมายเลขบัตรประชาชนเป็นตัวเลขเท่านั้น</div>
                        <div v-if="!$v.identity_number.maxLength">ต้องกรอกหมายเลขบัตรประชาชน ไม่เกินกว่า
                            13 หลัก</div>
                        <div v-if="!$v.identity_number.minLength">ต้องกรอกหมายเลขบัตรประชาชน ไม่น้อยกว่า
                            13 หลัก</div>
                    </div>
                </b-form-group>

                <b-form-group class="mb-3" label="กรุณากรอก เบอร์โทรศัพท์ 10 หลัก">
                    <b-form-input v-model="phone" type="text" placeholder="เบอร์โทรศัพท์ติดต่อกลับ"
                        :class="{ 'is-invalid': submitted && $v.phone.$error }" maxlength="10"></b-form-input>
                    <div v-if="submitted && $v.identity_number.$error" class="invalid-feedback">
                        <div v-if="!$v.phone.required">ต้องกรอกหมายเลขบัตรประชาชน</div>
                        <div v-if="!$v.phone.numeric">
                            ต้องกรอกตัวเลขเท่านั้น</div>
                        <div v-if="!$v.phone.maxLength">ต้องกรอกหมายเลข ไม่เกินกว่า
                            10 หลัก</div>
                        <div v-if="!$v.phone.minLength">ต้องกรอกหมายเลข ไม่น้อยกว่า
                            10 หลัก</div>
                    </div>
                </b-form-group>
            </b-form>
            <h4 class="text-warning">ยืนยันการทำรายการชำระหลักสูตรฝึกอบรมผู้ตรวจมาตรฐานการแพทย์แผนไทย (ภาคทฤษฎี)</h4>
            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="danger" size="xl" type="button" @click="inspectInstallmentForm">
                        <h5 class="text-white">ยืนยัน</h5>
                    </b-button>
                </div>

                <div class="col-5 d-block d-grid">
                    <b-button variant="" size="xl" @click="showConfirmInstallmentModal = !showConfirmInstallmentModal;">
                        <h5 class="text-white">ปิด</h5>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal v-if="registed_data != null" size="xl" v-model="showReceiptModal" no-close-on-esc no-close-on-backdrop
            hide-header-close centered>
            <Receipt v-if="registed_data != null" :receipt_no="'L30' + registed_data.id" :reference_no="registed_data.referenceNo"
                :created_dt="registed_data.created_dt" :firstname="registed_data.firstname || '-'"
                :lastname="registed_data.lastname || '-'" :phone="registed_data.phone || '-'"
                :identity_no="registed_data.identity_number || '-'"
                receipt_name="ค่าสมัครเข้าหลักสูตรฝึกอบรมผู้ตรวจมาตรฐานการแพทย์แผนไทย (ภาคทฤษฎี)"
                :amount="registed_data.amount" :fee="registed_data.fee ? registed_data.fee.toFixed(2) : registed_data.fee"
                :total="registed_data.amount && registed_data.fee ? (registed_data.amount + registed_data.fee).toFixed(2) : (registed_data.amount + registed_data.fee)">
            </Receipt>
            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="" size="xl" @click="showReceiptModal = !showReceiptModal;">
                        <h5 class="text-white">ปิด</h5>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showLinkModal" no-close-on-esc no-close-on-backdrop hide-header-close centered>
            <h4>ลิงค์ สำหรับแชร์ หรือส่งให้เพื่อน</h4>
            <input class="form-control" :value="link" readonly="true"/>
            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="" size="xl" @click="showLinkModal = false">
                        <h5 class="text-white">ปิด</h5>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

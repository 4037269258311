<script>
import ax from '../helpers/restful/service';
import L2Button from "@/components/l2-button.vue";
import L3Button from "@/components/l3-button.vue";

export default {
    data() {
        return {
            d: [
                {
                    value: "a1",
                    text: "๑.ผู้พัฒนาผลิตภัณฑ์ตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "52",
                    doc_no_th: "๕๒",
                    pdf: "/pdf/a52.pdf",
                },
                {
                    value: "a2",
                    text: "๒.ผู้ให้บริการดูแลสุขภาพตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "53",
                    doc_no_th: "๕๓",
                    pdf: "/pdf/a53.pdf",
                },
                {
                    value: "a3",
                    text: "๓.ผู้ให้บริการนวดไทยตามมาตรฐานประจำประเทศไทย",
                    doc_no: "54",
                    doc_no_th: "๕๔",
                    pdf: "/pdf/a54.pdf",
                },
                {
                    value: "a4",
                    text: "๔.ผู้ให้บริการนวดไทยตามมาตรฐานประจำประเทศมาเลเซีย",
                    doc_no: "55",
                    doc_no_th: "๕๕",
                    pdf: "/pdf/a55.pdf",
                },
                {
                    value: "a5",
                    text: "๕.ผู้ให้บริการนวดไทยตามมาตรฐานประจำประเทศมาเลเซีย",
                    doc_no: "56",
                    doc_no_th: "๕๖",
                    pdf: "/pdf/a56.pdf",
                },
                {
                    value: "a6",
                    text: "๖.ผู้ปลูกหรือผลิตสมุนไพรตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "57",
                    doc_no_th: "๕๗",
                    pdf: "/pdf/a57.pdf",
                },
                {
                    value: "a7",
                    text: "๗.ผู้ให้บริการกัญชาตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "58",
                    doc_no_th: "๕๘",
                    pdf: "/pdf/a58.pdf",
                },
                {
                    value: "a8",
                    text: "๘.ผู้ประกอบกิจการส่งออกผลิตภัณฑ์ตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "59",
                    doc_no_th: "๕๙",
                    pdf: "/pdf/a59.pdf",
                },
                {
                    value: "a9",
                    text: "๙.ผู้ตรวจมาตรฐานการแพทย์แผนไทย",
                    doc_no: "60",
                    doc_no_th: "๖๐",
                    pdf: "/pdf/a60.pdf",
                },
                {
                    value: "a10",
                    text: "๑๐.อาสาพัฒนาการแพทย์แผนไทยประจำตำบล",
                    doc_no: "61",
                    doc_no_th: "๖๑",
                    pdf: "/pdf/a61.pdf",
                },
                {
                    value: "a11",
                    text: "๑๑.อาสาพัฒนาการแพทย์แผนไทยประจำหมู่บ้าน",
                    doc_no: "62",
                    doc_no_th: "๖๒",
                    pdf: "/pdf/a62.pdf",
                },
                {
                    value: "a12",
                    text: "๑๒.ผู้แทนสถาบันที่เกี่ยวข้องกับวิชาชีพการแพทย์แผนไทยตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "63",
                    doc_no_th: "๖๓",
                    pdf: "/pdf/a63.pdf",
                },
                {
                    value: "a13",
                    text: "๑๓.ผู้พัฒนางานวิจัยและเทคโนโลยีตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "64",
                    doc_no_th: "๖๔",
                    pdf: "/pdf/a64.pdf",
                },
                {
                    value: "a14",
                    text: "๑๔.ผู้ให้บริการฝึกอบรมตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "65",
                    doc_no_th: "๖๕",
                    pdf: "/pdf/a65.pdf",
                },
                {
                    value: "a15",
                    text: "๑๕.ผู้ให้บริการโภชนบำบัดตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "66",
                    doc_no_th: "๖๖",
                    pdf: "/pdf/a66.pdf",
                },
                {
                    value: "a16",
                    text: "๑๖.ผู้ให้บริการด้านจิตบำบัดตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "67",
                    doc_no_th: "๖๗",
                    pdf: "/pdf/a67.pdf",
                },
                {
                    value: "a17",
                    text: "๑๗.ผู้ให้บริการด้านเวชศาสตร์ชะลอวัยและความงามตามมาตรฐานการแพทย์แผนไทย",
                    doc_no: "68",
                    doc_no_th: "๖๘",
                    pdf: "/pdf/a68.pdf",
                },
            ],
            showModal: false,
            showConfirmModal: false,
            showInfoModal: false,
            request_selected_in_db: [],
            request_selected: [],
        
            isExist: false,
            registed_data: null,
            submitted: false,
        }
    },
    validations: {
        
    },
    components: {
        "l2-button" : L2Button,
        "l3-button" : L3Button,
    },
    created() {
        this.checkStatus();
    },
    watch: {
        request_selected: {
            handler: (val, oldVal) => {
                console.log("old:", oldVal);
                console.log("new:", val);
            },
            deep: true,
        },
    },
    methods: {
        closeModal() {
            this.showModal = !this.showModal;
        },
        openSuccessModal() {
            this.tryToRequestUpdate();
        },
        closeSuccessModal() {
            this.$router.go();
        },
        async tryToRequestUpdate() {
            this.showConfirmModal = false;
            const uid = JSON.parse(localStorage.getItem('auth.currentUser')).uid;
            console.log("uid:", uid);
            const resp = await ax.post("/registed-a", {
                uid: uid,
                request: this.request_selected,
            });
            console.log("resp:", resp);
            if (resp.status != 200) {
                alert("เกิดข้อผิดพลาด กรุณาดำเนินการใหม่อีกครั้ง");
                return;
            }

            const responseData = resp.data;
            console.log("responseData:", responseData);
            this.showConfirmModal = true;
            // this.$router.go();
        },
        async checkStatus() {
            const uid = JSON.parse(localStorage.getItem('auth.currentUser')).uid;
            const resp = await ax.post("/check-status-a", { uid: uid });
            console.log("resp:", resp);
            if (resp.status != 200) {
                alert("เกิดข้อผิดพลาด กรุณาดำเนินการใหม่อีกครั้ง");
                return;
            }
            this.request_selected = resp.data.request || [];
            this.request_selected_in_db = resp.data.request || [];
        },
        
    },
};
</script>

<template>
    <div>
        <div class="bg-black py-2 px-2 mb-2 ">
            <h5 class="text-white">ยื่นขอรับใบอนุญาตเป็นสมาชิกเพื่อรับสิทธิประโยชน์ในด้านที่เกี่ยวข้องได้
                ตามที่สภาการแพทย์แผนไทยกำหนด หรือรับรองแล้วแต่กรณี</h5>
        </div>
        <h5>
            เปิดลงทะเบียน :<br />
            ๑ กันยายน ๒๕๖๖ ถึง ๒๙ ธันวาคม ๒๕๖๖
        </h5>
        <div class="d-block d-grid">
            <b-button variant="primary" block lg="5" @click="showModal = !showModal;" type="button">
                <span style="font-size: 20px;">
                    ดูเพิ่มเติม
                </span>
            </b-button>
        </div>

        <b-modal size="xl" v-model="showModal" title="ยื่นขอรับใบอนุญาตเป็นสมาชิกฯ" no-close-on-esc no-close-on-backdrop
            hide-header-close scrollable centered>
            <h4 class="fs-5 text text-primary">กรุณาเลือกประเภทสมาชิกที่ต้องการสมัคร</h4>
            <b-input-group>
                <b-form-group v-slot="" >
                    <b-form-checkbox v-for="dd in d" :key="dd.value" v-model="request_selected" :value="dd.value" :disabled="request_selected_in_db.indexOf(dd.value) >= 0"><span class="ms-1">{{ dd.text }}</span> <a :href="'/pdf/a'+dd.doc_no+'.pdf'" target="_blank" >(รายละเอียดตามประกาศที่ {{ dd.doc_no }})</a></b-form-checkbox>
                </b-form-group>
            </b-input-group>

            <p class="mt-2 fs-6 text">
                <span class="fs-5 text text-primary">กำหนดการ ลงทะเบียน</span><br />
                วันที่ ๑ กันยายน ๒๕๖๖ ถึง ๒๙ ธันวาคม ๒๕๖๖
            </p>

            <p class="mt-2 fs-6 text">
                <span class="fs-5 text text-primary">กำหนดการ แจ้งผลการรับสมัคร</span><br />
                วันที่ ๑ มกราคม ๒๕๖๗ ถึง ๒๙ กุมภาพันธ์ ๒๕๖๗
            </p>

            <p class="mt-2 fs-6 text">
                <span class="fs-5 text text-primary">คุณสมบัติ</span><br />
                ๑.เป็นผู้ที่สนใจพัฒนาความรู้ในด้านที่เกี่ยวข้องกับคุณสมบัติตามประเภทสมาชิกที่กำหนดไว้<br />
                ๒.มีอายุไม่ต่ำกว่ายี่สิบปีบริบูรณ์<br />
                ๓.เป็นผู้ที่ผ่านหลักเกณฑ์และเงื่อนไขการอบรมและการสอบความรู้ในหลักสูตรฝึกอบรม ดังที่กำหนดไว้ตามประกาศฯ ของแต่ละประเภทสมาชิก<br />
                ๔.มีความรู้ในวิชาชีพการแพทย์แผนไทย ดังต่อไปนี้<br />
                <span class="ms-3"></span>๔.๑.ได้รับการฝึกอบรมจากสถาบันหรือสถานพยาบาลที่สภาการแพทย์แผนไทยรับรองโดยมีผู้ประกอบวิชาชีพการแพทย์แผนไทยซึ่งได้รับอนุญาตให้ถ่ายทอดความรู้เป็นผู้ให้การอบรมและต้องสอบผ่านความรู้ตามที่กำหนดไว้ในข้อบังคับสภาการแพทย์แผนไทย  หรือ<br />
                <span class="ms-3"></span>๔.๒.ได้รับปริญญาหรือประกาศนียบัตรเทียบเท่าปริญญาสาขาการแพทย์แผนไทยหรือสาขาการแพทย์แผนไทยประยุกต์จากสถาบันการศึกษาที่สภาการแพทย์แผนไทยรับรองและต้องสอบผ่านความรู้ตามที่กำหนดไว้ในข้อบังคับสภาการแพทย์แผนไทย  หรือ<br />
                <span class="ms-3"></span>๔.๓.เป็นผู้ที่ส่วนราชการรับรองความรู้การแพทย์พื้นบ้านไทย  โดยผ่านการประเมินหรือการสอบตามที่กำหนดไว้ในข้อบังคับสภาการแพทย์แผนไทย<br />
            </p>
            
            <p class="mt-2 fs-6 text">
                <span class="fs-5 text text-primary">สิทธิที่จะได้รับ</span><br />
                ๑.ได้รับใบอนุญาตให้เป็นสมาชิกสภาการแพทย์แผนไทยตามประเภทลงทะเบียน<br />
                ๒.สามารถขอขึ้นทะเบียนและขอการรับรองมาตรฐานกับสภาการแพทย์แผนไทยตามประเภทสมาชิกในด้านที่เกี่ยวข้องได้<br />
                ๓.สามารถขอหนังสืออนุมัติหรือวุฒิบัตรแสดงความรู้ความชำนาญในการประกอบวิชาชีพการแพทย์แผนไทย หรือการประกอบวิชาชีพการแพทย์แผนไทยประยุกต์หรือขอหนังสือแสดงวุฒิอื่นในวิชาชีพการแพทย์แผนไทย โดยปฏิบัติตามข้อบังคับ ระเบียบ หรือประกาศของสภาการแพทย์แผนไทยว่าด้วยการนั้นตามประเภทสมาชิกในด้านที่เกี่ยวข้องได้<br />
                ๔.แสดงความเห็นเป็นหนังสือเกี่ยวกับกิจการของสภาการแพทย์แผนไทยตามประเภทสมาชิกในด้านที่เกี่ยวข้องได้ โดยส่งไปยังคณะกรรมการเพื่อพิจารณา<br />
                ๕.รับสิทธิประโยชน์เพิ่มเติมอื่นใดตามประเภทสมาชิกในด้านที่เกี่ยวข้องได้ ตามที่สภาการแพทย์แผนไทยกำหนดหรือรับรองแล้วแต่กรณี<br />
            </p>
            
            <p class="mt-2 fs-6 text">
                <span class="fs-5 text text-primary">ค่าธรรมเนียมต่อประเภทสมาชิก</span><br />
                ๑.ค่าจดทะเบียนสมาชิก<br />
                <span class="ms-3"></span>ค่าจดทะเบียนสมาชิก ๑,๐๐๐ บาทต่อครั้ง<br />
                ๒.ค่าบำรุง<br />
                <span class="ms-3"></span>ค่าบำรุงการเป็นสมาชิก ต่อ ๕ ปี ๒๐๐ บาทต่อครั้ง<br />
                ๓.ค่าธรรมเนียมในการสมัคร<br />
                <span class="ms-3"></span>เป็นไปตามที่สภาการแพทย์แผนไทยกำหนดหรือรับรองแล้วแต่กรณี<br />
                ๔.ค่าธรรมเนียมในการออกเอกสารประจำตัวใหม่<br />
                <span class="ms-3"></span>เป็นไปตามที่สภาการแพทย์แผนไทยกำหนดหรือรับรองแล้วแต่กรณี<br />

                <span class="text-danger">*** หมายเหตุ : ต้องดำเนินการชำระตามขั้นตอนที่กำหนด<b><u>ภายหลังการประกาศผลการพิจารณากลั่นกรองคุณสมบัติ</u></b></span>
            </p>

            <template #modal-footer>
                <div class="col-5 d-grid">
                    <b-button variant="success" type="button" size="xl" @click="openSuccessModal">
                        <h5 class="text-white">ยื่นคำขอฯ</h5>
                    </b-button>
                </div>
                <div class="col-5 d-grid">
                    <b-button variant="danger" size="xl" @click="closeModal">
                        <h5 class="text-white">ปิด</h5>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="lg" v-model="showConfirmModal" title="" no-close-on-esc no-close-on-backdrop
            hide-header-close centered>
            
            <h4 class="text-success">การดำเนินยื่นคำขอฯ เสร็จสิ้น</h4>
            <p class="mt-2 text">
                ระบบจะทำการตรวจสอบคุณสมบัติของท่านและประกาศผลตามที่สภาการแพทย์แผนไทยกำหนด<br/><br/>

                กรุณาตรวจสอบการกรอกข้อมูลของท่านใน <router-link to="/profile" class="text fw-bold">Profile</router-link> ให้เรียบร้อย<br/>
                พร้อมตรวจสอบคุณสมบัติและ<span class="text text-danger fw-bold">เข้ารับการฝึกอบรม และทดสอบความรู้ตามหลักเกณฑ์ และเงื่อนไข ที่ประกาศฯ กำหนด</span>
            </p>

            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-lg-6">
                        <div class="card">
                            <div class="card-body text-center">
                                <l2-button additional_text="(สำหรับการขอเป็นสมาชิกทั่วไป)"></l2-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-lg-6">
                        <div class="card">
                            <div class="card-body text-center">
                                <l3-button additional_text="(สำหรับการขอเป็นสมาชิกประเภทผู้ตรวจมาตรฐานการแพทย์แผนไทยตามประกาศที่ 60)"></l3-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="" size="xl" @click="closeSuccessModal">
                        <h5 class="text-white">ปิด</h5>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>

export default {
    props:{
        additional_text: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            
        }
    },
    
    components: {
        
    },
    created() {
        
    },
    mounted(){
    },
    watch: {
        
    },
    methods: {
        
    },
};
</script>

<template>
    <div>
        <div class="bg-black py-2 px-2 mb-2 ">
            <h5 class="text-white">ยื่นคำขอการรับรอง</h5>
        </div>
        <div class="d-block d-grid">
            <b-button variant="primary" block lg="5" @click="$router.push({ path: '/course-inspection' });" type="button">
                <span style="font-size: 20px;">
                    ดูเพิ่มเติม
                </span>
            </b-button>
        </div>
    </div>
</template>

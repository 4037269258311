<script>
import ax from '@/helpers/restful/service.js';

import Horizontal from "./horizontal";
// import L1Button from "@/components/l1-button.vue";
// import L2Button from "@/components/l2-button.vue";
// import L3Button from "@/components/l3-button.vue";
import Inform1Button from "@/components/inform1-button.vue";
// import TeacherRegistedButton from "@/components/teacher-registed-button";
import NoticeButton from "@/components/notice-button.vue";

import CourseButton from "@/components/course-button.vue";
import CourseInspectionButton from "@/components/course-inspection-button.vue";
import UseCodeButton from "@/components/use-code-button.vue";

export default {
    components: {
        Horizontal,
        // "l1-button": L1Button,
        // "l2-button": L2Button,
        // "l3-button": L3Button,
        "notice-button": NoticeButton,
        "inform1-button": Inform1Button,
        // "teacher-registed-button" : TeacherRegistedButton,
        "course-button" : CourseButton,
        "course-inspection-button" : CourseInspectionButton,
        "use-code-button" : UseCodeButton
    },
    data() {
        return {
            uid: JSON.parse(localStorage.getItem('auth.currentUser')).uid,
            course_registed: [],

            is_loaded: false,
        };
    },
    computed: {},
    async mounted() {
        document.body.classList.add('authentication-bg');
        await this.getCoursePublic();
    },
    methods: {
        async getCoursePublic(){
            this.is_loaded = false;
            const resp = await ax.post("/get-course-public", { uid: this.uid });
            console.log("course public:", resp);

            this.is_loaded = true;

            if(resp.status == 200){
                this.course_registed = resp.data.course;
                return;
            }
            this.course_registed = [];
            return
        }
    },
};
</script>

<template>
    <div>
        <Horizontal layout="horizontal">
            <div class="card py-4">
                <h3 class="text text-center">ประกาศลงทะเบียน</h3>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <notice-button></notice-button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="card">
                        <div class="card-body text-center">
                            <l2-button></l2-button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="card">
                        <div class="card-body text-center">
                            <l3-button></l3-button>
                        </div>
                    </div>
                </div>-->
                <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="card">
                        <div class="card-body text-center">
                            <inform1-button></inform1-button>
                        </div>
                    </div>
                </div> 
                <!-- <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="card">
                        <div class="card-body text-center">
                            <teacher-registed-button></teacher-registed-button>
                        </div>
                    </div>
                </div>  -->
                <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="card">
                        <div class="card-body text-center">
                            <course-inspection-button></course-inspection-button>
                        </div>
                    </div>
                </div> 
                <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="card">
                        <div class="card-body text-center">
                            <use-code-button></use-code-button>
                        </div>
                    </div>
                </div> 
            </div>
            
            <div class="row" v-if="is_loaded">
                <div class="col-12" v-for="(course, i) in course_registed" :key="i">
                    <div class="card">
                        <div class="card-body text-center">
                            <course-button :course_id="course.course_id"></course-button>
                        </div>
                    </div>
                </div>
            </div>
        </Horizontal>
    </div>
</template>

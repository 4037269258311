<script>

export default {
    props:{
        additional_text: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            showModal: false,
            showConfirmModal: false,
            showConfirmInstallmentModal: false,
            showReceiptModal: false,
            showLinkModal: false,
            link: "https://thaimed.v2vservice.com/course-registed?open_dialog=inform1",
            payment: {
                token: process.env.VUE_APP_GB_TOKEN,
            },
            isExist: false,
            isPaid: false,
            registed_data: null,
            installment_no: 0,

            identity_number: "",
            firstname: "",
            lastname: "",
            phone: "",
            submitted: false,
            amount_origin: 18000,
            amount: 18000,

            discount_code: null,
            check_discount_timeout: null,
            check_loading: false,
        }
    },
    
    components: {
        
    },
    created() {
        
    },
    mounted(){
        console.log("this.$route.query.open_dialog", this.$route.query.open_dialog);
        if(this.$route.query.open_dialog == "inform1"){
            this.showModal = true;
        }
    },
    watch: {
        
    },
    methods: {
        closeModal() {
            this.showModal = !this.showModal;
        },
        openLink() {
            window.open("https://forms.gle/WpBsn6V7zXxXToj98", "_blank");
            return;
        },
        openLinkModal(){
            this.showLinkModal = true;
        },
    },
};
</script>

<template>
    <div>
        <div class="bg-black py-2 px-2 mb-2 ">
            <h5 class="text-white">ยื่นข้อเสนอในการร่วมจัดกิจกรรมการศึกษาต่อเนื่องร่วมกับสภาการแพทย์แผนไทย</h5>
        </div>
        <div class="d-block d-grid">
            <b-button variant="primary" block lg="5" @click="showModal = !showModal;" type="button">
                <span style="font-size: 20px;">
                    ดูเพิ่มเติม
                </span>
            </b-button>
        </div>

        <b-modal size="xl" v-model="showModal" title="สมัครเข้าอบรม" no-close-on-esc no-close-on-backdrop hide-header-close
            scrollable centered>
            <template #modal-header>
                <div class="col-12 text-end ">
                    <div class="me-0 end-0 top-0">
                        <button class="btn btn-outline-secondary" type="button"  @click="openLinkModal"><i class="fa fa-share-alt"></i></button>
                    </div>
                </div>
            </template>
            <h4 class="text-warning">ยื่นข้อเสนอในการร่วมจัดกิจกรรมการศึกษาต่อเนื่องร่วมกับสภาการแพทย์แผนไทย</h4>
            <p class="fs-5 text">
                <span class="fs-4 text">คุณสมบัติ ผู้มีสิทธิยื่นข้อเสนอฯ</span><br />
                ๑.สถานประกอบการ สถานศึกษา สถานพยาบาล องค์กร หรือหน่วยงานอื่นใดที่เป็นผู้จัดกิจกรรมอันมีวัตถุประสงค์เพื่อส่งเสริมงานของสภาการแพทย์แผนไทย<br/>
                ๒.เป็นบุคคลหรือนิติบุคคลตามที่กฎหมายแห่งราชอาณาจักรไทยกำหนด<br/>
                ๓.เป็นผู้ที่มีการดำเนินการเพื่อพัฒนาภูมิปัญญาด้านการแพทย์แผนไทยให้เกิดประโยชน์สูงสุด โดยให้มีคุณภาพและมีมาตรฐานสูงขึ้นอย่างต่อเนื่อง<br/>
                ๔.บุคคลที่ยื่นข้อเสนอ หรือนิติบุคคลที่ยื่นข้อเสนอ หรือบุคคลที่เกี่ยวข้องกับนิติบุคคลที่ยื่นข้อเสนอ ต้องไม่มีลักษณะต้องห้ามดังต่อไปนี้<br/>
                ๔.๑.เป็นผู้ประพฤติเสียหายซึ่ง สภาการแพทย์แผนไทย เห็นว่าจะนำมาซึ่งความเสื่อมเสียเกียรติศักดิ์แห่งวิชาชีพการแพทย์แผนไทย<br/>
                ๔.๒.เคยต้องโทษจำคุกโดยคำพิพากษาถึงที่สุดในคดีที่ สภาการแพทย์แผนไทย เห็นว่าจะนำมาซึ่งความเสื่อมเสียเกียรติศักดิ์แห่งวิชาชีพการแพทย์แผนไทย<br/>
                ๔.๓.เป็นผู้วิกลจริต จิตฟั่นเฟือนไม่สมประกอบ หรือเป็นโรคที่กำหนดไว้ในข้อบังคับสภาการแพทย์แผนไทย<br/><br/>

                <span class="fs-4 text">เอกสารประกอบการยื่นข้อเสนอฯ</span> <br/>
                (คลิ๊ก<a href="https://drive.google.com/drive/folders/1haf_KBkNjX6DqdNV_6CyP_SZqodndrdp?usp=sharing" target="_blank">ลิ้งค์</a> เพื่อรับแบบฟอร์มฯ)<br/>
                ๑. แบบแสดงรายละเอียดข้อเสนอในการร่วมจัดกิจกรรม<br/>
                ๒. แบบฟอร์มหลักสูตรหรือกิจกรรม<br/>
            </p>
            
            
            <template #modal-footer>
                <div class="container">
                    <div class="row mb-1">
                        <div class="col-1 col-xs-2 col-sm-2 col-md-2 col-xl-1">
                            <img src="@/assets/images/arrow-right.png" class="img-fluid" />
                        </div>
                        <div class="col-11 col-xs-10 col-sm-10 col-md-10 col-xl-11 d-grid g-0">
                            <b-button variant="primary" md size="md" @click="openLink">
                                <h5 class="text-white text-start"><br/>ยื่นข้อเสนอ</h5>
                            </b-button>
                        </div>
                    </div>
                </div>

                <div class="col-5 d-grid">
                    <b-button variant="danger" size="xl" @click="closeModal">
                        <h5 class="text-white">ปิด</h5>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showLinkModal" no-close-on-esc no-close-on-backdrop hide-header-close centered>
            <h4>ลิงค์ สำหรับแชร์ หรือส่งให้เพื่อน</h4>
            <input class="form-control" :value="link" readonly="true"/>
            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="" size="xl" @click="showLinkModal = false">
                        <h5 class="text-white">ปิด</h5>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
